import { parseTime, requireFun } from '@/utils'

export const option = {
  editBtn: false,
  delBtn: false,
  topPage: false,
  searchSize: 'small',
  border: true,
  expand: false,
  column: [
    {
      label: '变量名称',
      prop: 'fieldName',
      search: true,
      hide: true
    },
    {
      label: '创建时间',
      prop: 'createTime',
      search: true,
      hide: true,
      type: 'daterange',
      format: 'yyyy-MM-dd',
      valueFormat: 'yyyy-MM-dd'
    },
    {
      label: '属性名称',
      prop: 'fieldName'
    },
    {
      label: '属性code',
      prop: 'variableName'
    },
    // {
    //   label: '支持类型',
    //   prop: 'exportType',
    //   dicData: [
    //     {
    //       value: 0,
    //       label: '无支持类型'
    //     },
    //     {
    //       value: 1,
    //       label: '产品导出'
    //     },
    //     {
    //       value: 2,
    //       label: '订单导出'
    //     },
    //     {
    //       value: 3,
    //       label: '订单导出、产品导出'
    //     }
    //   ]
    // },
    {
      label: '导出类型',
      prop: 'templateUsageType',
      dicData: [
        {
          value: 1,
          label: '订单自定义导出'
        },
        {
          value: 2,
          label: '产品模板导出'
        }
      ]
    },
    {
      label: '状态',
      prop: 'isEnable',
      dicData: [
        {
          value: 1,
          label: '已启用'
        },
        {
          value: 0,
          label: '未启用'
        }
      ]
    },
    {
      label: '创建时间',
      prop: 'createTime',
      formatter(row) {
        return parseTime(row.createTime)
      }
    }
  ]
}

export const eidtOption = {
  menuAlign: 'left',
  searchSize: 'small',
  labelPosition: 'left',
  column: [
    {
      label: '属性名称',
      prop: 'fieldName',
      span: 22,
      labelWidth: '90',
      rules: [
        {
          required: true,
          message: requireFun('属性名称')
        }
      ]
    }
  ]
}
